.loader-wrapper {
  height: 100svh;
}

/* CSS Animation */

.loader-palestine-map {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* transform: scale(0.4); */
  /* opacity: 0.5; */
  transition: all 2s ease-in-out;
  animation: myAnim 2s ease 0s 1 normal forwards;
}

.loader-palestine-map .al-aqsa {
  opacity: 0;
  animation: myAnim2 1.1s ease 2s 1 normal forwards;
  animation-iteration-count: infinite;
}

@keyframes myAnim {
  0% {
    opacity: 0.2;
    transform: scale(0.98);
  }

  30% {
    opacity: 0.2;
    transform: scale(0.99);
  }

  100% {
    opacity: 0.8;
    transform: scale(1);
  }
}

@keyframes myAnim2 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Lottie Animation */

.loader-palestine-lottie .pal-anim {
  width: 300px;
  position: absolute;
  top: calc(50% - 157px);
  left: calc(50% - 150px);
  filter: brightness(150%);
  -webkit-filter: brightness(150%);
}
