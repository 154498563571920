#custom-modal .modal-card {
  border-radius: 28px;
}

#custom-modal .modal-card-foot,
#custom-modal .modal-card-head,
#custom-modal .modal-card-body {
  background-color: #efefef;
}

#custom-modal .modal-card-head {
  flex-direction: column;
  align-items: flex-start;
}

#custom-modal .modal-card-body {
  color: #49454f;
}

#custom-modal .modal-card-title {
  margin-top: 1rem;
  font-weight: bold;
}

#custom-modal .modal-card-sub-title {
  font-size: 14px;
  color: #49454f;
  font-weight: 300;
  margin-bottom: 10px;
}

#custom-modal button.button {
  font-family: inherit;
  color: var(--primary-color);
}

/* Hide upper close button */
#custom-modal .delete {
  display: none;
}

/* MEDIA QUERIES */

@media (max-width: 768px) {
  #custom-modal .modal-card {
    max-width: 80%;
  }
}
