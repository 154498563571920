body {
  margin: 0;
  font-family: "GHAITHSANS", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar */
::-webkit-scrollbar {
  display: none;
}

html {
  scrollbar-width: none; /* FF */
}

html:has(.modal.is-active) {
  overflow: hidden !important;
}

@font-face {
  font-family: "Rubik";
  src: url("./fonts/Rubik-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url("./fonts/Rubik-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url("./fonts/Rubik-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.button.is-success {
  background-color: #00d1b2 !important;
}

/* Helper Classes */
.flex-it {
  display: flex;
  align-items: center;
  justify-content: center;
}

.eng-text {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  direction: ltr;
}

.ara-text {
  font-family: "GHAITHSANS", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.rtl {
  direction: rtl !important;
}

@media only screen and (max-width: 1200px) {
  @font-face {
    font-family: "GHAITHSANS";
    src: url("./fonts/GHAITHSANS-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "GHAITHSANS";
    src: url("./fonts/GHAITHSANS-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "GHAITHSANS";
    src: url("./fonts/GHAITHSANS-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "GHAITHSANS";
    src: url("./fonts/GHAITHSANS-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "GHAITHSANS";
    src: url("./fonts/GHAITHSANS-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}
