.login {
  width: 100%;
  height: 100svh;
  max-height: 100svh;
  overflow: hidden;
  background-color: #0b0b27;
  background-image: url(../../images/bg-space-desktop.png);
}

.login-svg-wrapper {
  background-image: url(../../images/bg-galaxy-min.svg);
  height: 100%;
  background-position: top right;
  background-size: cover;
}

.login-content {
  height: 100%;
}

.login-home-navlink {
  color: #fff;
}

.login-al-aqsa-wrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 10000;
  mix-blend-mode: hard-light;
}

.login-al-aqsa-wrapper img.login-al-aqsa {
  max-width: 100%;
  max-height: 100%;
  opacity: 0.2;
}

.login-al-aqsa-map {
  position: absolute;
  left: 52px;
  bottom: 68px;
  transform: scale(0.9);
  transform-origin: bottom;
}

.login-al-aqsa-map img {
  opacity: 0.15;
}

.login-logo,
.login-lang-sel {
  position: fixed;
  color: #202020;
  font-weight: 500;
}

.login-logo {
  width: 115px;
  height: 115px;
  top: 4rem;
  left: 4rem;
  text-align: center;
}

.login-lang-sel {
  width: 100px;
  height: 45px;
  right: 5rem;
  bottom: 4rem;
  flex-direction: row-reverse;
  border-radius: 4px;
  cursor: pointer;
}

.login-lang-sel-text {
  padding: 0 10px;
}

#login-lang-sel-tooltip,
#login-form-submit-btn {
  font-family: "GHAITHSANS", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: #fff;
  /* background: var(--primary-color); */
}

#login-lang-sel-tooltip {
  width: 140px;
  opacity: 1;
}

.login-form-wrapper {
  width: 450px;
  padding: 40px 30px 85px 30px;
  border-radius: 8px;
  background-color: #f8f8f8;
  -webkit-box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.05);
}

.login-form-icon {
  text-align: center;
}

.login-form-label {
  color: #202020;
  font-size: 24px;
  font-weight: bold;
  padding: 0.75rem 0 1.5rem 0;
  text-align: center;
}

.login-form-input-wrapper {
  height: 56px;
  margin-bottom: 1.5rem;
  position: relative;
}

.login-form-input-label {
  position: absolute;
  color: #232323;
  padding-right: 10px;
  padding-top: 3px;
}

.login-form-input-wrapper input {
  width: 100%;
  height: 100%;
  background-color: #ececec;
  color: #202020;
  border: 0;
  outline: 0;
  padding: 22px 12px 4px 12px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.login-form-input-wrapper input.unempty {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.login-form-input-wrapper input::-webkit-input-placeholder {
  color: #8f8f8f;
  opacity: 1 !important; /* Chrome */
}

.login-form-input-wrapper input::placeholder {
  color: #8f8f8f;
  opacity: 1 !important; /* Firefox */
}

.login-form-input-wrapper input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #8f8f8f;
  opacity: 1 !important;
}

.login-form-submit-wrapper {
  position: relative;
  z-index: 10000;
}

.eye-icon {
  position: absolute;
  top: 0;
  left: 8px;
  cursor: pointer;
  background-color: #ececec;
  height: 100%;
}

.eye-icon path {
  transform: translate(8px, 6px);
}

.login-err-msg {
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
  text-align: right;
  color: #f14668;
  position: absolute;
}

.login-err-msg-email {
  color: #3169f7;
}

/* Post Sign In Splash Animation */

.splash {
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.8s ease;
  border-radius: 50%;
  background-color: #00d1b2;
  transition-delay: 0.25s;
}

.expanded {
  animation: expand 2s ease-in-out;
  animation-delay: 0.5s;
}

@keyframes expand {
  0% {
    height: 0;
    width: 0;
    padding-bottom: 0;
    background-color: #00d1b2;
  }
  100% {
    height: auto;
    width: 1000%;
    padding-bottom: 1000%;
    background-color: #0b0b27;
  }
}

@-o-keyframes expand {
  0% {
    height: 0;
    width: 0;
    padding-bottom: 0;
    background-color: #00d1b2;
  }
  100% {
    height: auto;
    width: 1000%;
    padding-bottom: 1000%;
    background-color: #0b0b27;
  }
}

@-moz-keyframes expand {
  0% {
    height: 0;
    width: 0;
    padding-bottom: 0;
    background-color: #00d1b2;
  }
  100% {
    height: auto;
    width: 1000%;
    padding-bottom: 1000%;
    background-color: #0b0b27;
  }
}

@-webkit-keyframes expand {
  0% {
    height: 0;
    width: 0;
    padding-bottom: 0;
    background-color: #00d1b2;
  }
  100% {
    height: auto;
    width: 1000%;
    padding-bottom: 1000%;
    background-color: #0b0b27;
  }
}

/* Fix login input autocomplete native colors */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #202020 !important;
}

.hidden-app-version {
  position: absolute;
  top: 4px;
  right: 4px;
  color: #2d2d44;
  font-size: 12px;
}

/* Remove Chrome Autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ececec inset !important;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 820px) {
  .login {
    background-image: url(../../images/bg-space-mobile.png);
    background-size: 100% 100%;
  }
  .login-svg-wrapper {
    background-image: none;
  }
  .login-logo {
    width: 65px;
    height: 65px;
    font-size: 12px;
    top: 2rem;
    left: 2rem;
  }
  .login-lang-sel {
    bottom: 2rem;
    right: 2rem;
  }
}

@media only screen and (max-width: 575px) {
  .login-form-wrapper {
    width: 75%;
  }
}

@media only screen and (max-width: 525px) {
  .login-form-wrapper {
    width: 70%;
    padding: 25px 25px 80px 25px;
  }
  .login-form-icon img {
    width: 85px;
  }
  .login-form-label {
    font-size: 20px;
  }
  .login-form-input-label {
    font-size: 10px;
    padding-top: 8px;
  }
  .login-form-input-wrapper input {
    font-size: 14px;
  }
  #login-form-submit-btn {
    font-size: 14px;
  }
  .login-err-msg {
    font-size: 12px;
  }
}

@media only screen and (max-width: 425px) {
  .login-form-wrapper {
    width: 80%;
  }
  .login-form-icon img {
    width: 75px;
  }
}

@media only screen and (orientation: landscape) and (max-height: 650px) {
  .login-logo {
    width: 65px;
    height: 65px;
    font-size: 12px;
    top: 2rem;
    left: 2rem;
  }
  .login-lang-sel {
    bottom: 2rem;
    right: 2rem;
  }
}

@media only screen and (max-width: 820px) {
  .login-svg-wrapper {
    background-image: url(../../images/bg-galaxy-mob.svg);
    background-position: top center;
  }
  .login-al-aqsa-map {
    display: none;
  }
}
